@import "../colors";
@import "../spacing";
@import "../typography";

$spacer:                      $spacing-xxs;

// TYPOGRAPHY
$font-size-base:              $base-text-size;
$h1-font-size:                $spacer * 3;

// COLORS
$primary:                     $gamoteca-color-green;
$secondary:                   $gamoteca-color-black;

// BUTTONS
$btn-border-radius:           $spacer * 2.5;


$sizes: (
    0: 0%
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 990px
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);