// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

$gamoteca-color-green: #20cfc6;
$gamoteca-color-orange: #dd753b;
$gamoteca-color-orange-light: #ed7b39;
$gamoteca-color-grey: #e0e0e0;
$gamoteca-color-light-grey: #f8f8f8;
$gamoteca-color-muted: #999999;
$gamoteca-color-dark: #222222;
$gamoteca-color-black: #000000;
$gamoteca-color-white: #fff;
$gamoteca-blank-blue: #A0B7EC;
$gamoteca-pure-blue: #2055cf;
$gamoteca-color-red: rgb(252, 237, 237);

$alert-error: $gamoteca-color-orange;
$alert-success: #10dc60;
$alert-warning: #ffce00;

$active-gray: #e2e2e2;
$light-gray: #e8e8e8;
$mid-gray: #d1d1d1;
$pale-gray: #9e9e9e;
$vivid-gray: #555555;

$dirty-white: #fbfbfb;
$active-light-green: #c1f2ef;

$text-color: $gamoteca-color-dark;
$icon-color: #454545;

$card-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
$back-btn-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

$input-border: 1px solid $gamoteca-color-dark;

$highlighted-form-area: #f0f0f0;

$card-devider: #efefef;

/** Ionic CSS Variables **/
:root {
  /** text **/
  --ion-text-color: #222222;

  /** primary **/
  --ion-color-primary: #20cfc6;
  --ion-color-primary-rgb: 32, 207, 198;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1cb6ae;
  --ion-color-primary-tint: #36d4cc;

  /** secondary **/
  --ion-color-secondary: #fff;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #000;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #e0e0e0;
  --ion-color-secondary-tint: #ffffff;

  /** tertiary **/
	--ion-color-tertiary: #2055cf;
	--ion-color-tertiary-rgb: 32,85,207;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #1c4bb6;
	--ion-color-tertiary-tint: #3666d4;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #dd753b;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #c26734;
  --ion-color-warning-tint: #e0834f;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: darken( #f04141, 30% );
  --ion-color-danger-tint: lighten( #f04141, 30% );

  /** dark **/
  --ion-color-dark: #454545;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

ion-button[color="secondary"] {
  --border-color: #000;
  --border-style: solid;
  --border-width: 1px;
}
