@import "typography";
@import "colors";
@import "spacing";

* {
  font-family: 'Roboto';
}

*:focus {
  outline: none;
}

html {
  font-size: 62.5%
}

body {
  color: $text-color;
  font-weight: normal;
  font-size: $base-text-size;
  line-height: $base-line-height;
}

html, body {
  background: var(--ion-color-primary-contrast) url(/assets/images/splash.avif) center center no-repeat;
  background-size: 50%;

  @media(max-width: 576px) {
    background-size: cover;
  }
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: $spacing-xl;
}

ion-content {
  --background: #F5F7FB;
}

.vcenter {
  display: flex;
  align-items: center;
}

.btn.btn-primary {
  color: $gamoteca-color-white;
}

.page-title-with-submenu {
  max-width: 96rem;
  margin: $spacing-xl auto 3.8rem;
  font-family: 'Mukta', 'Roboto', sans-serif;
  font-size: $heading36;
  font-weight: bold;
}

.submenu {
  display: flex;
  max-width: 96rem;
  margin: 0 auto;
  font-family: 'Mukta', 'Roboto', sans-serif;
  font-size: 2.2rem;
  padding-left: 0;
}

.submenu-item {
  margin-right: 2.2rem;
  cursor: pointer;
  list-style-type: none;

  &.active {
    font-weight: bold;
  }
}

.active-submenu {
  font-weight: bold;
}

.note {
  font-size: $txt-size14;
  margin: 6px 0 6px 0;
  line-height: 1.71;
  letter-spacing: 0.23px;
}

.highlighted-form-area {
  margin: $spacing-xxs 0;
  padding: 1rem 1.6rem;
  border-radius: 2px;
  background-color: $highlighted-form-area;
}

[ng-reflect-apply-class="info-text-popper"] {
  cursor: pointer;
}

.info-text-popper {
  z-index: 2000;
  background: $gamoteca-color-white;
  color: $text-color;
  font-weight: 500;
  font-size: $small-text-size;
  line-height: $spacing-md;
  padding: $spacing-xxs $spacing-sm;
  border: 1px solid rgba(0, 0, 0, .03) !important;
  box-shadow: 0 5px 5px rgba(0, 0, 0, .24), 0 0 2px rgba(0, 0, 0, .12) !important;
  margin: 0 !important;

  .ngxp__arrow {
    opacity: 0;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  >.col,
  >[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.horizontal-vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horizontal-center-vertical-bottom {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
}

.avatars {
  img {
    height: 2.1rem;
    border-radius: 2.1rem;
    margin-right: $spacing-xxs;
    border: solid 1px $gamoteca-color-light-grey;
  }
}

.add-button {
  height: $spacing-xl;
  width: $spacing-xl;
}

.back-button {
  position: absolute;
  left: -9rem;
  top: 0;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 5rem;
  box-shadow: $back-btn-shadow;
  background: $gamoteca-color-white;
  padding: 1.65rem;
  cursor: pointer;
}

.back-button-disabled {
  opacity: 0.25;
}

// Hide intercom on mobile
@media(max-width: 576px) {

  .intercom-launcher,
  .intercom-launcher-frame,
  .intercom-launcher-badge-frame {
    display: none !important;
  }
}

.w-auto {
  width: auto !important;
}

.min-h-100 {
  min-height: 100%;
}

.header-back-button {
  --box-shadow: none !important;
}

.disabled {
  color: $mid-gray !important;
  cursor: default !important;
}

.pre {
  white-space: pre-line;
}

ion-button.intercom-show {
  width: 42px;
  height: 42px;
  margin-left: 10px;
  --border-width: 0 !important;
  --box-shadow: none;
  --border-radius: 30px;

  mat-icon {
    color: #222;
    font-size: 26px !important;
  }
}

ion-action-sheet.my-custom-class ion-icon {
  font-size: 24px !important;
}

ion-action-sheet.my-custom-class .action-sheet-button {
  font-size: 16px !important;
}

ion-loading.change-organization-loading {
  font-size: 14px !important;
}

ion-alert.forgot-password-alert {
  font-size: 14px !important;
}

ion-alert.forgot-password-alert .alert-title {
  font-size: 20px !important;
  padding-bottom: 6px;
  font-weight: bold;
}

ion-alert.forgot-password-alert .alert-sub-title {
  font-size: 16px !important;
}

ion-alert.forgot-password-alert .alert-button {
  font-size: 14px !important;
}

ion-toast.copy-toast::part(message) {
  font-size: 14px !important;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 1.5rem;
}

ion-textarea.custom-textarea {
  --highlight-color: none;
  --border-color: none;
}

.ion-valid.sc-ion-textarea-md-h {
  --highlight-color: none;
}

ion-modal.script-empty-list {
  --width: 90vw;
  --max-width: 970px;
  --height: 80vh;
  --border-radius: 1rem;

  .modal-content {
    --max-height: 80vh;
    overflow-y: scroll;
  }
}

ion-modal.script-url-editor-modal {
  --max-width: 465px;
  --max-height: 189px;
  --border-radius: .5rem;
}

ion-modal.task-edit-modal {
  --width: 1024px;
  --max-width: 80vw;
  --border-radius: 5px;
  --height: 90vh;

  ion-content {
    --background: #fff;

    .page-title {
      margin-top: -30px;
      margin-bottom: 20px;
    }
  }

  task-preview {
    position: relative;
    transition: bottom 0.2s;
  }
}

ion-modal.join-code-popover {
    --width: 80vw;
    --max-width: 465px;
    --background: rgba(0,0,0,0.7);
}  

ion-modal.delete-account {
  --max-width: 500px;
}

ion-modal.join-company-popover {
  --width: 700px;
  --height: 360px;
  --border-radius: 22px;
}

ion-modal.app-update {
  --max-width: 290px;
}

ion-modal.browser-recommendation {
  --max-width: 515px;
}

ion-modal.select-organize-mode{
  --max-width: 465px;
}

ion-modal.role-edit {
  --max-width: 465px;
}

ion-modal.create-ro-role {
  --max-width: 465px;
}

ion-modal.monitoring-task-view {
  --background: transparent;
  --box-shadow: none;
}

ion-popover.organization-join {
  --width: 80vw;
  --max-width: 465px;
  --background: rgba(0,0,0,0.7);
}

ion-modal.organization-join {
  --width: 80vw;
  --max-width: 465px;
  --background: rgba(0, 0, 0, 0.7);
}

ion-modal.organization-plan {
  --width: 850px;
  --max-width: 850px;
}

ion-modal.join-code-popover {
  --width: 80vw;
  --max-width: 465px;
  --background: rgba(0, 0, 0, 0.7);
}

ion-modal.delete-account {
  --max-width: 500px;
}

ion-modal.player-profile {
  --width: 80vw;
  --max-width: 400px;
  --background: transparent;
  --box-shadow: none;
}

ion-modal.play-modal {
  --max-width: 550px;
  --max-height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    max-height: calc(95vh - 30px);
    overflow-y: auto;
    padding: 15px;
  }
}

ion-modal.organization-plan {
  --max-width: 800px;
}

ion-popover {
  --background: white;
  --color: black;
  --offset-y: 10px;
  --box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  --width: 200px;
  --arrow-background: grey;
}

ion-popover::part(backdrop) {
  background-color: transparent;
}

ion-popover::part(arrow) {
  --background: rgba(226, 220, 220, 0.383);
}

.custom-toast::part(message) {
  font-size: 15px;
  font-weight: bold;
}

ion-searchbar.custom {

  .searchbar-input-container {
    .searchbar-input {
      font-size: 1.5rem;
    }
  }
}