@import "../colors";
@import "../spacing";
@import "../typography";

popper-content {
    .ngxp__container {
        min-width: 200px;
        display: block!important;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;

        &[aria-hidden="true"] {
            visibility: hidden;
        }
    }
}