[dir="rtl"], .direction-rtl {

    form {
      label {
        text-align: inherit !important;
      }

      select.form-control {
        background-position: 5px center;
      }

      .input-wrapper::after {
        left: 30px;
        right: auto;
      }
    }

    .rtl-flip {
      transform: scaleX(-1);
    }

    .float-left {
        float: right !important;
    }

    .float-right {
        float: left !important;
    }

    @function mapReverse ($map) {
      $result: null;
  
      @if type-of($map) == "map" {
          $keys: map-keys($map);
          $map-reversed: ();
          
          @for $i from length($keys) through 1 {
              $map-reversed: map-merge(
                  $map-reversed,
                  (nth($keys, $i): map-get($map, nth($keys, $i)))
              );
          }
  
          @if type-of($map-reversed) == "map" {
              $result: $map-reversed;
          } @else {
              @warn 'There was an error reversing the order of "#{$map}"';
          }
      } @else {
          @warn '"#{$map}" is not a valid map';
      }
  
      @return $result;
    }


    @if $enable-grid-classes {
        .container {
          @include make-container();
        }
      }
      
      // Fluid container
      //
      // Utilizes the mixin meant for fixed width containers, but with 100% width for
      // fluid, full width layouts.
      
      @if $enable-grid-classes {
        .container-fluid {
          @include make-container();
        }
      }
      
      // Row
      //
      // Rows contain and clear the floats of your columns.
      
      @if $enable-grid-classes {
        .row {
          @include make-row();
        }
      
        // Remove the negative margin from default .row, then the horizontal padding
        // from all immediate children columns (to prevent runaway style inheritance).
        .no-gutters {
          margin-right: 0;
          margin-left: 0;
      
          > .col,
          > [class*="col-"] {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }

    $direction: 'rtl';
    $right: right;
    $left: left;
    
    
    @mixin make-col-offset($size, $columns: $grid-columns) {
        $num: calc(#{$size} / #{$columns});
        margin-#{$left}: 0;
        margin-#{$right}: if($num == 0, 0, calc(#{$size} / #{$columns} * 100%));
    }

    @mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
        @each $breakpoint in map-keys($breakpoints) {
            $infix: breakpoint-infix($breakpoint, $breakpoints);

            @for $i from 1 through $columns {
                .col#{$infix}-#{$i} {
                  @media (min-width: $breakpoint) {
                    @include make-col($i, $columns);
                  }
                }
            }
        }

        @each $breakpoint in map-keys($breakpoints) {
            $infix: breakpoint-infix($breakpoint, $breakpoints);

            @for $i from 1 through $columns {
                .col#{$infix}-#{$i} {
                  @extend %grid-column;
                }
              }
              .col#{$infix},
              .col#{$infix}-auto {
                @extend %grid-column;
              }

            @include media-breakpoint-up($breakpoint, $breakpoints) {
                @for $i from 0 through ($columns - 1) {
                    @if not ($infix == "" and $i == 0) {
                        .offset#{$infix}-#{$i} {
                            @include make-col-offset($i, $columns);
                        }
                    }
                }
            }
        }
    }

    @if $enable-grid-classes {
        @include make-grid-columns();
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
          $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      
          @each $prop, $abbrev in (margin: m, padding: p) {
            @each $size, $length in $spacers {
              .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
              .#{$abbrev}t#{$infix}-#{$size},
              .#{$abbrev}y#{$infix}-#{$size} {
                #{$prop}-top: $length !important;
              }
              .#{$abbrev}r#{$infix}-#{$size},
              .#{$abbrev}x#{$infix}-#{$size} {
                #{$prop}-left: $length !important;
                // #{$prop}-right: auto !important;
              }
              .#{$abbrev}b#{$infix}-#{$size},
              .#{$abbrev}y#{$infix}-#{$size} {
                #{$prop}-bottom: $length !important;
              }
              .#{$abbrev}l#{$infix}-#{$size},
              .#{$abbrev}x#{$infix}-#{$size} {
                #{$prop}-right: $length !important;
              }
            }
          }
      
          // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
          @each $size, $length in $spacers {
            @if $size != 0 {
              .m#{$infix}-n#{$size} { margin: -$length !important; }
              .mt#{$infix}-n#{$size},
              .my#{$infix}-n#{$size} {
                margin-top: -$length !important;
              }
              .mr#{$infix}-n#{$size},
              .mx#{$infix}-n#{$size} {
                margin-left: -$length !important;
              }
              .mb#{$infix}-n#{$size},
              .my#{$infix}-n#{$size} {
                margin-bottom: -$length !important;
              }
              .ml#{$infix}-n#{$size},
              .mx#{$infix}-n#{$size} {
                margin-right: -$length !important;
              }
            }
          }
      
          // Some special margin utils
          .m#{$infix}-auto { margin: auto !important; }
          .mt#{$infix}-auto,
          .my#{$infix}-auto {
            margin-top: auto !important;
          }
          .mr#{$infix}-auto,
          .mx#{$infix}-auto {
            margin-left: auto !important;
          }
          .mb#{$infix}-auto,
          .my#{$infix}-auto {
            margin-bottom: auto !important;
          }
          .ml#{$infix}-auto,
          .mx#{$infix}-auto {
            margin-right: auto !important;
          }
        }
      }
      
}

[dir="ltr"] {
    .rtl-text {
      unicode-bidi: normal;
    }
}

::ng-deep [dir="rtl"] {
  .modal-close-icon {
    right: 0 !important;
    left: 1.1rem !important;
  }
}