@import "../typography";
@import "../spacing";

ion-radio-group:not(.no-override) {

  ion-label {
    font-size: 14px !important;
  }

  ion-item {
    --background: trasparent;
    --inner-padding-start: 0;
    --padding-start: 0;
    --min-height: 0;
  }

  ion-radio {
    width: 16px;
    height: 16px;
  }
}

ion-modal {
  --backdrop-opacity: 0.32 !important;

  &.auto-height {
    --height: auto;

      padding: 25px;
      --border-radius: 8px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
      .ion-page {
        position: relative;
        contain: content;
        overflow-y: scroll;

        .modal-content {
          --ion-padding: 30px;

          h2.modal-title {
            margin: 0 0 18px 0;
            font-family: 'Mukta', 'Roboto', sans-serif;
            font-size: 2.2rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.09;
            letter-spacing: normal;
          }

          .confirmation-description {
            margin-bottom: 2.6rem;
            font-size: $txt-size16;
          }
        }

        .modal-close-icon {
          position: absolute;
          cursor: pointer;
          top: 1.1rem;
          right: 1.1rem;
          font-size: 2.2rem;
          color: #777;
        }
      }
  }

  &.sm-modal {
    --width: 46.5rem;
  }
}

ion-button {
  --padding-start: 17px;
  --padding-end: 17px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  height: 34px;
  min-height: 1rem;
  letter-spacing: 0.3px;
  font-size: 16px;
  text-transform: none;
  letter-spacing: .3px;

  &:not([fill="clear"]) {
    --box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }

  &[fill="clear"] {
    &.px-0 {
      --padding-start: 0;
      --padding-end: 0;
      font-size: 14px;
    }

    ion-ripple-effect {
      display: none;
    }

    &[color="secondary"] {
      --border-width: 0 !important;
      color: #000;

      [slot="icon-only"] {
        color: #000;
      }
    }
  }

  mat-icon {
    font-size: 2.2rem;
    margin-right: 1px;

    &[name="create"] {
      font-size: 1.5rem;
      margin-right: 0.4rem;
      margin-left: -0.5rem;
    }
  }

  ion-spinner {
    width: 20px;
    margin-left: -10px;
    margin-right: 2px;
  }

  &[color="secondary"] {
    ion-spinner {
      color: var(--ion-color-secondary-contras);
    }
  }


  &.smaller {
    --padding-start: 16px;
    --padding-end: 18px;
    --padding-top: 0px;
    letter-spacing: 0.26px;
    font-size: 14px;
  }
}

ion-tab-bar {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

ion-tab-button {
  font-size: 1.4rem;
}

ion-title {
  line-height: 2.2rem;
}

@media(max-width: 576px) {
  ion-toolbar {
    --min-height: 60px;
  }

  ion-tab-bar {
    height: 43px;
  }

  ion-tab-button {
    max-width: none;

    ion-badge {
      margin: -9px -5px;
    }
  }

}

app-root.app-player {
  ion-header {
    box-shadow: none;
    &:after {
      content: none;
      border-bottom: 1px solid #eee;
    }

    ion-title {
      letter-spacing: 0.48px;
      font-size: 24px;
      line-height: 26px;
      font-weight: bold;
      font-family: 'Roboto';

      @media(max-width: 576px) {
        .title-lg, .title-xlg, .title-xxxlg {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          white-space: normal;
        }
  
        .title-lg {
          font-size: 22px;
          line-height: 25px;
        }
  
        .title-xlg {
          font-size: 21px;
          line-height: 24px;
        }
  
        .title-xxxlg {
          font-size: 17px;
          line-height: 22px;
        }
      }

    }

    ion-toolbar {
      --background: white;
      --min-height: 70px;

      &:last-child {
        --border-width: 0;
      }
    }
  }

  ion-content {
    --background: #fff;
  }
}