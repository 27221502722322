$spacing-xxxxxs: 0.4rem;
$spacing-xxxxs: 0.6rem;
$spacing-xxxs: 0.8rem;
$spacing-xxs: 1.0rem;
$spacing-xs: 1.2rem;
$spacing-sm: 1.6rem;
$spacing-md: 2rem;
$spacing-lg: 2.4rem;
$spacing-xl: 3.0rem;
$spacing-xxl: 4.0rem;
$spacing-xxxl: 5.0rem;
