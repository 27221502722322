.tour-step {
  background-color: white !important;
  box-shadow: 2px 2px 2px black;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans" !important;
  text-align: justify;
}
.tour-step-vertical {
  margin-top: 1% !important;
  margin-bottom: 1% !important;
}

.tour-step-horizontal {
  margin-left: 1% !important;
  margin-right: 1% !important;
}

div.shepherd-content {
  margin: 1% !important;
}

.shepherd-title {
  font-family: Mukta !important;
  font-weight: bold;
  font-size: 1.2em;
}

div.shepherd-text {
  color: black;
  font-size: 1em;
}

.shepherd-header {
  background-color: white !important;
}

button.shepherd-button {
  border-radius: 64px;
  font-size: 1.1em !important;
  display: inline-block !important;
  text-align: center !important;
  padding: 2% 4% 2% 4% !important;
  font-weight: bold;
  margin-bottom: 2%;
}

button.next-btn {
  background-color: #20cfc6 !important;
  color: white !important;
  border: 2px solid #20cfc6 !important;
}

button.next-btn:hover {
  background-color: #1cb6ae !important;
  color: white !important;
  border: 2px solid #1cb6ae !important;
}

button.previous-btn {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
}

button.previous-btn:hover {
  background-color: #e7e7e7 !important;
}

.tour-gif {
  width: 100%;
  border: 1px solid black;
  box-shadow: 1px 1px 1px black;
  margin-bottom: 2%;
}

.tour-gif-container {
  text-align: center;
}
.tour-gif-sm {
  width: 90%;
}

footer.shepherd-footer {
  margin-top: 0.5% !important;
  padding: 0.5% !important;
}

.tour-highlight {
  animation-name: highlight;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  border-radius: 50%;
  box-shadow: 0 0 2px white;
}

@keyframes highlight {
  0% {
    background-color: white;
    box-shadow: 0 0 2px white;
  }
  50% {
    background-color: #ffc107;
    box-shadow: 0 0 2px #ffc107;
  }
  100% {
    background-color: white;
    box-shadow: 0 0 2px white;
  }
}
.shepherd-has-title[data-popper-placement^="bottom"] > .shepherd-arrow:before {
  background-color: white !important;
}

.disabled-section {
  pointer-events: none;
}

.active-section {
  pointer-events: all !important;
}

.follow-up-plus {
  border-radius: 50%;
  padding: 2px;
  background-color: #20cfc6;
  color: white;
}