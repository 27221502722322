@import "typography";
@import "spacing";
@import "colors";

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

form {
  .form-group {
    margin-bottom: $spacing-xs;
    margin-bottom: 1.8rem;

    & label:not(.note) {
      display: block;
      font-weight: 600;
    }

    p.value-text {
      font-size: 1.6rem;
      padding: 10px 0 20px 0;
      margin: 0;
      letter-spacing: 0.5px;
    }
  }

  .editable {
    display: inline-block;
    margin: 0 0 .2rem 0;

    & + input {
      display: none;
    }

    &.active {
      display: none;

      & + input {
        display: block;
      }
    }
  }

  .input-row {
    align-items: flex-end;
    margin-bottom: $spacing-xxs;

    div {
      padding-left: 0;
    }
  }

  .input-hint {
    font-size: $small-text-size;
  }

  label {
    line-height: $line-height-28;
    margin-bottom: .2rem;
    font-size: 14px;
    letter-spacing: 0.23px;
    line-height: 2.6rem;
  }

  input {
    outline: none;
    border: 0;
    border-bottom: 1px solid #000;
    border-radius: 0;
    padding-left: 0;
    background: transparent;
    letter-spacing: 0.47px;

    &[disabled] {
      display: inline-block;
      width: calc(100% - 3rem);
      border: 0;
      background: transparent;
    }

    &:not(:disabled) + mat-icon {
      display: none;
    }
  }

  input.ng-invalid.ng-touched {
    border-bottom: 1px solid $alert-error;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
  }

  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      cursor: pointer;
    }
  }

  input[type="number"] {
    display: block;
    height: $spacing-xl;
    line-height: $spacing-xl;
    border: $input-border;
    border-radius: $spacing-xxxxxs;
    padding: 0.375rem 0 0.375rem 0.75rem;

    &::-webkit-inner-spin-button {
      padding: 3px;
      opacity: 1;
      margin-right: 6px;
    }
  }

  input[type="number"].ng-invalid.ng-touched  {
    border: 1px solid $alert-error;
  }

  input[type="checkbox"] {
    position: relative;
    padding-top: 2px;
    appearance: none;
    width: 2.4rem;
    border: none;
    margin: 0 2px 0 -2px;
    color: inherit;
    outline: none;
    font-family             : 'Material Icons';

    transition: 300ms ease-out;
    cursor: pointer;

    &::after {
      content: '\e835';
      font-size: 22px;
    }

    &:checked::after {
      content: '\e834';
      font-size: 22px;
      margin-bottom: -2px;
    }

    &[disabled] {
      color: $pale-gray;
    }

    + span {
      margin-left: .35em;
    }

    & + label {
      cursor: pointer;
    }
  }

  .form-check {
    padding-left: 0;
    display: flex;
    align-items: center;
    margin: -2px 0 -7px 5.5px;
  }

  textarea, ion-textarea {
    outline: none;
    width: 100%;
    padding: 0.8rem 0.5rem;
    letter-spacing: 0.47px;
    --padding-start: .5rem;
    --padding-end: .5rem;
    --padding-bottom: .5rem;
    --padding-top: .5rem;
    border: $input-border;
    border-radius: $spacing-xxxxxs;
    resize: none;
  }

  textarea.ng-invalid.ng-touched {
    border: 1px solid $alert-error;
  }

  select {
    &.form-control {
      display: block;
      height: $spacing-xl;
      border: $input-border;
      border-radius: $spacing-xxxxxs;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMTI4IDE5MmwxMjggMTI4IDEyOC0xMjh6Ii8+PC9zdmc+);
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 20px;
      padding-right: 24px;
      text-overflow: ellipsis;
    }
  }

  select.ng-invalid.ng-touched {
    border: 1px solid $alert-error;
  }

  .input-wrapper {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 23px;
      bottom: 0;
      border: .5px solid black;
      height: 2.8rem;
    }
  }

  .input-wrapper-invalid {
    &::after {
      border: .5px solid $alert-error;
    }

    select {
      border: 1px solid $alert-error;
    }
  }

  .invalid-field {
    letter-spacing: 0.33px;
    color: $gamoteca-color-orange-light;
    line-height: 2.4rem;
    font-size: 1rem;
    margin-bottom: -8px;
  }

  .radio {
    margin: 0.5rem 1rem 0.5rem 0.5rem;

    label {
      font-weight: 600;
    }

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      + .radio-label {
        cursor: pointer;

        &:before {
          font-family: 'Ionicons', sans-serif;
          content: '\f1f6';
          font-size: 24px;
          font-weight: normal;
          vertical-align: top;

          font-family: 'Material Icons';
          content: '\E836';
        }
      }

      &:checked {
        + .radio-label {
          &:before {
            content: '\f1f7';
            content: '\E837';
          }
        }
      }

      &:disabled {
        + .radio-label {
          &:before {
            color: $pale-gray;
          }
        }
      }
    }
  }

  .label-with-popper {
    display: flex !important;
    align-items: center;

    mat-icon {
      font-size: 1.5rem;
      margin-bottom: .3rem;
      margin-left: $spacing-xxxxxs;
      margin-bottom: -0.1rem;
      margin-left: 0.3rem;
      cursor: pointer;
    }

    ion-icon {
      cursor: pointer;
    }
  }


  .ng2-tag-input {
    border-bottom: 1px solid #000 !important;


    tag-input-form {
      tag + & {
        input {
            padding: 0 .5rem !important;
        }
      }

      & input {
        padding-left: 0 !important;
        min-width: 190px;
      }
    }

    tag {
      height: 28px;
      line-height: 28px;
      padding: 0 10px 0 6px;
      border-radius: 5px;
      background-color: #c1f2ef;
      letter-spacing: 0.23px;
      color: #222222;

      &:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
        background-color: #c1f2ef;
      }

      .tag__text {
        margin-right: 10px;
      }

      delete-icon svg{
        height: 28px !important;
        vertical-align: middle !important;
      }
    }
  }
}
