@import "spacing";

$line-height-13: 1.3rem;
$line-height-16: 1.6rem;
$line-height-20: 2.0rem;
$line-height-24: 2.4rem;
$line-height-26: 2.6rem;
$line-height-28: 2.8rem;
$line-height-32: 3.2rem;
$line-height-38: 3.8rem;
$line-height-40: 4.0rem;
$line-height-50: 5.0rem;

$txt-size11: 1.1rem;
$txt-size12: 1.2rem;
$text-size13: 1.3rem;
$txt-size14: 1.4rem;
$txt-size16: 1.6rem;
$txt-size18: 1.8rem;
$heading45: 4.5rem;
$heading375: 3.75rem;
$heading36: 3.6rem;
$heading34: 3.4rem;
$heading30: 3rem;
$heading24: 2.4rem;
$heading20: 2.0rem;
$heading15: 1.5rem;

$base-text-size: $txt-size14;
$small-text-size: $txt-size12;

$base-line-height: $line-height-20;

h1,
.h1 {
  font-size: $heading30;
  margin-top: 0;
  margin-bottom: $spacing-lg;
  font-family: Mukta;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.77;
  letter-spacing: normal;
}

h2,
.h2 {
  margin-bottom: $spacing-xl;
  font-family: Mukta;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
}

mat-icon,
.mat-icon {
  font-size: $txt-size18;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
