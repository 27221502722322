@use 'sass:math';
@import "overrides";

@import '../../../node_modules/@ionic/angular/css/core.css';
@import '../../../node_modules/@ionic/angular/css/normalize.css';
@import '../../../node_modules/@ionic/angular/css/structure.css';
@import '../../../node_modules/@ionic/angular/css/typography.css';
@import '../../../node_modules/@ionic/angular/css/display.css';
@import '../../../node_modules/@ionic/angular/css/padding.css';
@import '../../../node_modules/@ionic/angular/css/float-elements.css';
@import '../../../node_modules/@ionic/angular/css/text-alignment.css';
@import '../../../node_modules/@ionic/angular/css/text-transformation.css';
@import '../../../node_modules/@ionic/angular/css/flex-utils.css';

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "../../../node_modules/bootstrap-scss/functions";
@import "../../../node_modules/bootstrap-scss/variables";
@import "../../../node_modules/bootstrap-scss/mixins";
@import "../../../node_modules/bootstrap-scss/root";



:not([dir="rtl"]) {
  @import "../../../node_modules/bootstrap-scss/grid";
}

@import "bootstrap-scss/tables";
@import "../../../node_modules/bootstrap-scss/forms";
@import "../../../node_modules/bootstrap-scss/buttons";
@import "../../../node_modules/bootstrap-scss/dropdown";
@import "../../../node_modules/bootstrap-scss/pagination";
@import "bootstrap-scss/alert";
@import "bootstrap-scss/utilities/align";
@import "bootstrap-scss/utilities/background";
@import "bootstrap-scss/utilities/borders";
@import "bootstrap-scss/utilities/clearfix";
@import "bootstrap-scss/utilities/display";
@import "bootstrap-scss/utilities/embed";
@import "bootstrap-scss/utilities/flex";
@import "bootstrap-scss/utilities/float";
@import "bootstrap-scss/utilities/overflow";
@import "bootstrap-scss/utilities/position";
@import "bootstrap-scss/utilities/screenreaders";
@import "bootstrap-scss/utilities/shadows";
@import "bootstrap-scss/utilities/sizing";
@import "bootstrap-scss/utilities/stretched-link";
:not([dir="rtl"]) {
  @import "bootstrap-scss/utilities/spacing";
}

@import "bootstrap-scss/utilities/text";
@import "bootstrap-scss/utilities/visibility";

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import "colors";
@import "typography";
@import "spacing";
@import "global";
@import "form";
@import "rtl";

@import "../../../node_modules/bootstrap-scss/mixins/breakpoints";

$sizes: (
  0: 0%,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

.material-icons {
  font-family: 'Material Icons' !important;
}

ion-toast.member-invitation-toast {
  --color: #000000;
}

ion-toast.member-invitation-toast::part(message) {
  font-weight: bold;
}

ion-toast.member-invitation-toast::part(button) {
  color: black;
  font-weight: bold;
}

ion-toast.error {
  --background: #f9ecec;
  --box-shadow: inset 5px 0 0 red, 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  /* Mimics the left border */
}

ion-toast.success {
  --background: #e5f8e4;
  --box-shadow: inset 5px 0 0 green, 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  /* Mimics the left border */
}